import { css as original } from '@custom/defaults/theme/Components/AuthPage/styles';
import { merge } from 'lodash';
import Color from 'color';
import { primary } from '../../../colors';

export const css = merge(original, {
  authBackgroundImage: {
    backgroundPosition: '60% center',
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      // background: 'linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%)',
      background: `linear-gradient(-15deg, ${Color(primary).alpha(0.2).rgb().toString()} 70%, ${Color(primary)
        .lighten(1.25)
        .alpha(0.9)
        .rgb()
        .toString()} 100%)`,
    },
  },
});

export default css;
