import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { black, green, primary, white } from '../../../colors';
import { colors } from '../../Styles';
import Color from 'color';

import { css as original } from '@custom/defaults/theme/Components/Button/styles';
import { merge } from 'lodash';
import { generalBorderRadius } from '../../../variables';

// const {orange, secondary} = colors;

/* tslint:disable object-literal-key-quotes */
/* tslint:disable object-literal-sort-keys */

export const css = merge(original, {
  button: {
    lineHeight: '1 !important',
    display: 'flex',
    alignItems: 'center',
    '&.ant-btn-round': {
      borderRadius: `${generalBorderRadius} !important`,
    },
    '&.ant-btn-sm': {
      fontSize: '.875em !important',
    },
    '&.ant-btn-round.ant-btn-lg': {
      padding: '2px 20px 4px',
      '& .anticon': {
        position: 'relative',
        top: 2,
      },
    },
    // '&.ant-btn-primary:not([disabled])': {
    //     background: `${secondary} !important`,
    //     color: `${black} !important`,
    //     fontWeight: 'bold',
    //     borderColor: 'transparent',
    //     position: 'relative',
    //     overflow: 'hidden',
    //     border: 0,
    //     '& > *': {
    //         position: 'relative',
    //         zIndex: 1,
    //     },
    //     '&:hover': {
    //         color: `${white} !important`,
    //     },
    //     '&:active, &:focus': {
    //         background: `${orange} !important`,
    //     },
    //     '&:before': {
    //         content: 'none',
    //     },
    // },
    '&.green': {
      background: `${green} !important`,
      borderColor: 'transparent',
      position: 'relative',
      overflow: 'hidden',
      border: 0,
    },
    '&.secondary': {
      background: `${primary} !important`,
      color: `${white} !important`,
      borderColor: 'transparent',
      position: 'relative',
      overflow: 'hidden',
      border: 0,
    },
    '&.wide': {
      width: '100%',
      justifyContent: 'center',
    },
  },
  button2: {},
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    button: {
      fontSize: '0.875rem',
      padding: ['0.3125rem', '1.25rem'],
      '&.ant-btn-lg': {
        fontSize: '1rem',
      },
    },
  },
});

export default css;
