import documentsRu from './translations/ru/documents.json';
import printFormsRu from './translations/ru/printForms.json';
import widgetsRu from './translations/ru/widgets.json';
import headerRu from './translations/ru/header.json';
import contractRu from './translations/ru/contract.json';

export const translations = {
  ru: {
    documents: documentsRu,
    printForms: printFormsRu,
    widgets: widgetsRu,
    header: headerRu,
    contract: contractRu,
  },
};
